var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('z-plate',{attrs:{"title":"评选活动管理"},scopedSlots:_vm._u([{key:"titleRight",fn:function(){return [_c('el-button',{attrs:{"type":"primary","icon":"el-icon-circle-plus-outline"},on:{"click":_vm.addActivity}},[_vm._v("添加")]),_c('el-button',{attrs:{"icon":"el-icon-edit"},on:{"click":_vm.editActivity}},[_vm._v("修改")]),_c('el-button',{attrs:{"icon":"el-icon-delete"},on:{"click":_vm.delActivity}},[_vm._v("删除")])]},proxy:true}])},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],staticClass:"z-table",staticStyle:{"width":"100%"},attrs:{"data":_vm.page.content},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"60"}}),_c('el-table-column',{attrs:{"type":"index","label":"序号","width":"60"}}),_c('el-table-column',{attrs:{"prop":"name","label":"活动名称","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"label":"学校上报时间","min-width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.uploadStartTime)+" ~ "+_vm._s(row.uploadEndTime))]}}])}),_c('el-table-column',{attrs:{"label":"专家评选时间","min-width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.reviewStartTime)+" ~ "+_vm._s(row.reviewEndTime))]}}])}),_c('el-table-column',{attrs:{"label":"上报数量"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.videoCount > 0)?_c('el-button',{staticStyle:{"font-weight":"bold"},attrs:{"type":"text"},on:{"click":function($event){return _vm.goToPage('0', row.id)}}},[_vm._v(" "+_vm._s(row.videoCount))]):_vm._e(),(row.videoCount == 0)?_c('span',[_vm._v(_vm._s(row.videoCount))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"操作","width":"490","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{on:{"click":function($event){return _vm.goToPage('1', row.id)}}},[_vm._v("评选分组管理")]),_c('el-button',{on:{"click":function($event){return _vm.goToPage('2', row.id)}}},[_vm._v("评委管理")]),_c('el-button',{on:{"click":function($event){return _vm.goToPage('3', row.id)}}},[_vm._v("评分进度")]),_c('el-button',{on:{"click":function($event){return _vm.goToPage('4', row.id)}}},[_vm._v("评分汇总")])]}}])})],1),(_vm.pagination.totalPages > 0)?_c('z-pagination',{attrs:{"page":_vm.pagination.page,"limit":_vm.pagination.size,"total":_vm.pagination.totalPages},on:{"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.pagination, "size", $event)},"pagination":_vm.init}}):_vm._e(),(_vm.addDialog)?_c('v-add-dialog',{attrs:{"show":_vm.addDialog,"data":{ id: _vm.activityid }},on:{"update:show":function($event){_vm.addDialog=$event},"success":_vm.init}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }