<template>
	<el-dialog class="z-dialog" title="新增评选活动" :visible.sync="isShow" width="640px" :close-on-click-modal="false">
		<el-form ref="form" :model="form" label-width="120px">
			<el-form-item label="活动名称">
				<el-input v-model="form.name" placeholder="请输入活动名称" style="width: 415px;" maxlength="50" />
			</el-form-item>

			<el-form-item label="作品上报时间">
				<el-date-picker v-model="form.uploadStartTime" type="datetime" format="yyyy-MM-dd HH:mm"
					value-format="yyyy-MM-dd HH:mm" placeholder="请选择开始时间" @change="startDatePicker"
					style="width: 200px"></el-date-picker>
				<span> - </span>
				<el-date-picker v-model="form.uploadEndTime" type="datetime" format="yyyy-MM-dd HH:mm"
					value-format="yyyy-MM-dd HH:mm" placeholder="请选择结束时间" @change="endDatePicker" style="width: 200px">
				</el-date-picker>
			</el-form-item>

			<el-form-item label="专家评审时间">
				<el-date-picker v-model="form.reviewStartTime" type="datetime" format="yyyy-MM-dd HH:mm"
					value-format="yyyy-MM-dd HH:mm" placeholder="请选择开始时间" @change="openDatePicker" style="width: 200px">
				</el-date-picker>
				<span> - </span>
				<el-date-picker v-model="form.reviewEndTime" type="datetime" format="yyyy-MM-dd HH:mm"
					value-format="yyyy-MM-dd HH:mm" placeholder="请选择结束时间" @change="closeDatePicker" style="width: 200px"></el-date-picker>
			</el-form-item>

			<el-form-item label="允许上报分类">
				<el-select v-model="classids" placeholder="请选择" multiple style="width: 415px;">
					<el-option v-for="item in channelList" :key="item.channelId"
						:label="item.groupName + '-' + item.channelName" :value="item.channelId"></el-option>
				</el-select>
			</el-form-item>

			<el-form-item label="是否上传附件">
				<el-switch v-model="fujian"></el-switch>
				<div class="fujianspan" v-if="fujian">
					附件标题名称：<el-input v-model="form.fujianTitle" placeholder="请输入附件标题名称" class="fujianTitleClass" maxlength="10"></el-input>
				</div>
			</el-form-item>

			<el-form-item label="是否使用随机码">
				<el-switch v-model="random"></el-switch>
			</el-form-item>

			<el-form-item label="是否自动分组">
				<el-switch v-model="autoGroupFlag"></el-switch>
			</el-form-item>

			<el-form-item label="上传附件提示">
				<quill-editor v-model="form.uploadMessage"
					:options="editorOption"
					@blur="onEditorBlur($event)"
					@focus="onEditorFocus($event)"
					@change="onEditorChange($event)">
				</quill-editor>
			</el-form-item>

			<el-form-item class="text-right">
				<el-button @click="isShow = false">取消</el-button>
				<el-button type="primary" @click="save">确定</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
export default {
	data() {
		return {
			editFlag: true,
			channelList: [],
			classids: [],
			form: {
				id: '',
				name: '',
				uploadStartTime: '',
				uploadEndTime: '',
				reviewStartTime: '',
				reviewEndTime: '',
				typeids: '',
				fujianFlag: 1,
				fujianTitle: '上传申报文本',
				uploadMessage: '',
				randomFlag: 0,
				autoGroup: 0
			},
			fujian: true,
			random: false,
			autoGroupFlag: false,
			editorOption: {
				theme: "snow",
				bounds: document.body,
				debug: "warn",
				modules: {
					// 工具栏配置
					toolbar: [
						["bold", "italic", "underline", "strike"],       // 加粗 斜体 下划线 删除线
						["blockquote", "code-block"],                    // 引用  代码块
						[{ list: "ordered" }, { list: "bullet" }],       // 有序、无序列表
						[{ indent: "-1" }, { indent: "+1" }],            // 缩进
						[{ size: ["small", false, "large", "huge"] }],   // 字体大小
						[{ header: [1, 2, 3, 4, 5, 6, false] }],         // 标题
						[{ color: [] }, { background: [] }],             // 字体颜色、字体背景颜色
						// [{ align: [] }],                                 // 对齐方式
						// ["clean"],                                       // 清除文本格式
						// ["link", "image", "video"]                       // 链接、图片、视频
					],
				}
			}
		}
	},

	props: {
		show: {
			type: Boolean,
			default: false
		},

		data: {}
	},

	computed: {
		isShow: {
			get() {
				return this.show;
			},
			set(value) {
				this.$emit("update:show", value);
			}
		}
	},
	mounted: {
		
	},
	created() {
		this.queryChannelList();
		if (this.data.id) {
			this.queryActivityInfo(this.data.id);
		}
	},
	methods: {
		onEditorBlur(editor){//失去焦点事件
			// console.log('失去焦点事件');
		},
		onEditorFocus(editor){//获得焦点事件
			// console.log('获得焦点事件');
		},
		onEditorChange({editor,html,text}){//编辑器文本发生变化
			//this.content可以实时获取到当前编辑器内的文本内容
			console.log('绑定的值uploadMessage:',this.form.uploadMessage);
			console.log('html:', html);
			console.log('text:', text);
		},
		queryActivityInfo(activityid) {
			this.$post('/queryActivityInfo', { activityid: activityid }).then(rep => {
				var activity = rep.content.activity;
				this.form.id = activity.id;
				this.form.name = activity.name;
				this.form.uploadStartTime = activity.uploadStartTime;
				this.form.uploadEndTime = activity.uploadEndTime;
				this.form.reviewStartTime = activity.reviewStartTime;
				this.form.reviewEndTime = activity.reviewEndTime;
				this.classids = rep.content.activityChannelList;
				if (activity.fujianFlag == 1) {
					this.fujian = true;
					if(activity.fujianTitle != ''){
						this.form.fujianTitle = activity.fujianTitle;
					}
				} else {
					this.fujian = false;
				}
				if (activity.randomFlag == 1) {
					this.random = true;
				} else {
					this.random = false;
				}
				if (activity.autoGroup == 1) {
					this.autoGroupFlag = true;
				} else {
					this.autoGroupFlag = false;
				}
				this.form.uploadMessage = activity.uploadMessage;
			})
		},
		startDatePicker() {
			var uploadStartTime = new Date(this.form.uploadStartTime).getTime();
			var uploadEndTime = new Date(this.form.uploadEndTime).getTime();
			var reviewStartTime = new Date(this.form.reviewStartTime).getTime();
			var reviewEndTime = new Date(this.form.reviewEndTime).getTime();
			if (this.form.uploadEndTime) {
				if (uploadEndTime <= uploadStartTime) {
					this.form.uploadStartTime = ''
					this.$message({
						type: 'info',
						message: '作品上报开始时间必须小于作品上报结束时间'
					});
					return;
				}
			}
			if (this.form.reviewStartTime) {
				if (reviewStartTime <= uploadStartTime) {
					this.form.uploadStartTime = ''
					this.$message({
						type: 'info',
						message: '作品上报开始时间必须小于专家评审开始时间'
					});
					return;
				}
			}
			if (this.form.reviewEndTime) {
				if (reviewEndTime <= uploadStartTime) {
					this.form.uploadStartTime = ''
					this.$message({
						type: 'info',
						message: '作品上报开始时间必须小于专家评审结束时间'
					});
					return;
				}
			}

		},
		endDatePicker() {
			var uploadStartTime = new Date(this.form.uploadStartTime).getTime();
			var uploadEndTime = new Date(this.form.uploadEndTime).getTime();
			var reviewStartTime = new Date(this.form.reviewStartTime).getTime();
			var reviewEndTime = new Date(this.form.reviewEndTime).getTime();
			if (this.form.uploadEndTime) {
				if (reviewStartTime <= uploadEndTime) {
					this.form.uploadEndTime = ''
					this.$message({
						type: 'info',
						message: '作品上报结束时间必须小于专家评审开始时间'
					});
					return;
				}
			}
			if (this.form.reviewEndTime) {
				if (reviewEndTime <= uploadEndTime) {
					this.form.uploadEndTime = ''
					this.$message({
						type: 'info',
						message: '作品上报结束时间必须小于专家评审结束时间'
					});
					return;
				}
			}
			if (this.form.uploadStartTime) {
				if (uploadStartTime >= uploadEndTime) {
					this.form.uploadEndTime = ''
					this.$message({
						type: 'info',
						message: '作品上报结束时间必须大于作品上报开始时间'
					});
					return;
				}
			}
		},
		openDatePicker() {
			var uploadStartTime = new Date(this.form.uploadStartTime).getTime();
			var uploadEndTime = new Date(this.form.uploadEndTime).getTime();
			var reviewStartTime = new Date(this.form.reviewStartTime).getTime();
			var reviewEndTime = new Date(this.form.reviewEndTime).getTime();
			if (this.form.uploadEndTime) {
				if (uploadEndTime >= reviewStartTime) {
					this.form.reviewStartTime = ''
					this.$message({
						type: 'info',
						message: '专家评审开始时间必须大于作品上报结束时间'
					});
					return;
				}
			}
			if (this.form.reviewEndTime) {
				if (reviewEndTime <= reviewStartTime) {
					this.form.reviewStartTime = ''
					this.$message({
						type: 'info',
						message: '专家评审开始时间必须小于专家评审结束时间'
					});
					return;
				}
			}
			if (this.form.uploadStartTime) {
				if (uploadStartTime >= reviewStartTime) {
					this.form.reviewStartTime = ''
					this.$message({
						type: 'info',
						message: '专家评审开始时间必须大于作品上报开始时间'
					});
					return;
				}
			}
		},
		closeDatePicker() {
			var uploadStartTime = new Date(this.form.uploadStartTime).getTime();
			var uploadEndTime = new Date(this.form.uploadEndTime).getTime();
			var reviewStartTime = new Date(this.form.reviewStartTime).getTime();
			var reviewEndTime = new Date(this.form.reviewEndTime).getTime();
			if (this.form.uploadEndTime) {
				if (uploadEndTime >= reviewEndTime) {
					this.form.reviewEndTime = ''
					this.$message({
						type: 'info',
						message: '专家评审结束时间必须大于作品上报结束时间'
					});
					return;
				}
			}
			if (this.form.reviewStartTime) {
				if (reviewStartTime >= reviewEndTime) {
					this.form.reviewEndTime = ''
					this.$message({
						type: 'info',
						message: '专家评审结束时间必须大于专家评审开始时间'
					});
					return;
				}
			}
			if (this.form.uploadStartTime) {
				if (uploadStartTime >= reviewEndTime) {
					this.form.reviewEndTime = ''
					this.$message({
						type: 'info',
						message: '专家评审结束时间必须大于作品上报开始时间'
					});
					return;
				}
			}
		},
		queryChannelList() {
			this.$post('/findChannelList').then(rep => {
				this.channelList = rep.content.list;
			});
		},
		save() {
			if (!this.form.name) {
				this.$message({
					type: 'info',
					message: '请填写活动名称'
				});
				return;
			}

			if (!this.form.uploadStartTime) {
				this.$message({
					type: 'info',
					message: '请选择作品上报开始时间'
				});
				return;
			}

			if (!this.form.uploadEndTime) {
				this.$message({
					type: 'info',
					message: '请选择作品上报结束时间'
				});
				return;
			}

			if (!this.form.reviewStartTime) {
				this.$message({
					type: 'info',
					message: '请选择专家评审开始时间'
				});
				return;
			}

			if (!this.form.reviewEndTime) {
				this.$message({
					type: 'info',
					message: '请选择专家评审结束时间'
				});
				return;
			}

			if (!this.classids.length) {
				this.$message({
					type: 'info',
					message: '请选择允许上报分类'
				});
				return;
			}

			if (this.fujian == true) {
				this.form.fujianFlag = 1;
				if (!this.form.fujianTitle) {
					this.$message({
						type: 'info',
						message: '请填写附件标题'
					});
					return;
				}
			} else {
				this.form.fujianFlag = 0;
			}
			if (this.random == true) {
				this.form.randomFlag = 1;
			} else {
				this.form.randomFlag = 0;
			}
			if (this.autoGroupFlag == true) {
				this.form.autoGroup = 1;
			} else {
				this.form.autoGroup = 0;
			}

			if(!this.form.uploadMessage){
				this.$message({
					type: 'info',
					message: '请填写上传附件提示'
				});
				return;
			}

			if (this.editFlag) {
				this.editFlag = false;
				this.form.typeids = this.classids.join();
				this.$post('/saveActivityInfo', this.form).then(rep => {
					if (rep.resultCode == 0) {
						this.editFlag = true;
						this.isShow = false;
						this.$emit('success');
						this.$message({
							type: 'success',
							message: '保存成功!'
						});
					} else {
						this.$message({
							type: 'error',
							message: '操作失败!'
						});
					}
				});
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.fujianspan {
	margin-left: 20px;
	display: inline-block;
	height: auto;
}

.fujianTitleClass {
	width: 180px;
}

.quill-editor {
//   height: 50px;
}
</style>