<template>
  <z-plate title="评选活动管理">
    <!-- title 右侧按钮 -->
    <template #titleRight>
      <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addActivity">添加</el-button>

      <el-button icon="el-icon-edit" @click="editActivity">修改</el-button>

      <el-button icon="el-icon-delete" @click="delActivity">删除</el-button>
    </template>

    <!-- 表格 -->
    <el-table class="z-table" :data="page.content" v-loading="tableLoading" @selection-change="handleSelectionChange"
      style="width: 100%">
      <el-table-column type="selection" width="60"></el-table-column>

      <el-table-column type="index" label="序号" width="60"></el-table-column>

      <el-table-column prop="name" label="活动名称" show-overflow-tooltip></el-table-column>

      <el-table-column label="学校上报时间" min-width="180">
        <template slot-scope="{ row }">{{ row.uploadStartTime }} ~ {{ row.uploadEndTime }}</template>
      </el-table-column>

      <el-table-column label="专家评选时间" min-width="180">
        <template slot-scope="{ row }">{{ row.reviewStartTime }} ~ {{ row.reviewEndTime }}</template>
      </el-table-column>

      <el-table-column label="上报数量">
        <template slot-scope="{ row }">
          <el-button v-if="row.videoCount > 0" type="text" style="font-weight: bold" @click="goToPage('0', row.id)">
            {{ row.videoCount }}</el-button>
          <span v-if="row.videoCount == 0">{{ row.videoCount }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="address" label="操作" width="490" fixed="right">
        <template slot-scope="{ row }">
          <el-button @click="goToPage('1', row.id)">评选分组管理</el-button>
          <el-button @click="goToPage('2', row.id)">评委管理</el-button>
          <el-button @click="goToPage('3', row.id)">评分进度</el-button>
          <el-button @click="goToPage('4', row.id)">评分汇总</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <z-pagination v-if="pagination.totalPages > 0" :page.sync="pagination.page" :limit.sync="pagination.size"
      :total="pagination.totalPages" @pagination="init"></z-pagination>

    <v-add-dialog v-if="addDialog" :show.sync="addDialog" :data="{ id: activityid }" @success="init" />
  </z-plate>
</template>

<script>
import VAddDialog from "./AddAppraise";
export default {
  components: {
    VAddDialog
  },

  data() {
    return {
      activityid: '',
      selids: [],
      addDialog: false,
      page: {
        content: []
      },
      pagination: {
        page: 0, //当前页
        size: 10, //分页条数
        totalPages: 0 //总条数
      },
      tableLoading: false
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    goToPage(type, activityid) {
      sessionStorage.setItem("group_activityid", activityid);
      if (type === '0') {
        this.$router.push({ name: 'AppraiseCheckSubmitWorks' })
      } else if (type === '1') {
        this.$router.push({ name: 'AppraiseGroup' });
      } else if (type === '4') {
        this.$router.push({ name: 'AppraiseSummarize' });
      } else if (type === '2') {
        this.$router.push({ name: 'AppraiseCheckAccount' })
      } else if (type === '3') {
        this.$router.push({ name: 'AppraiseProgress' })
      }
    },
    init() {
      sessionStorage.removeItem("group_activityid");
      let { pagination } = this;
      this.tableLoading = true;
      this.$post('/queryActivityList', {
        page: pagination.page,
        size: pagination.size
      }).then(rep => {
        this.page = rep.content.page;
        pagination.page = this.page.number;
        pagination.totalPages = this.page.totalElements;
        this.tableLoading = false;
      })
    },
    handleSelectionChange(val) {
      this.selids = val;
    },
    addActivity() {
      this.addDialog = true;
      this.activityid = '';
    },
    editActivity() {
      if (!this.selids.length) {
        this.$message({
          type: 'info',
          message: '请选择要修改的活动'
        });
        return;
      }

      if (this.selids.length > 1) {
        this.$message({
          type: 'info',
          message: '只能选择一个活动进行修改'
        });
        return;
      }
      this.addDialog = true;
      this.activityid = this.selids[0].id;
    },
    delActivity() {
      var activityids = new Array();
      if (!this.selids.length) {
        this.$message({
          type: 'info',
          message: '请选择要删除的活动'
        });
        return;
      }

      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        for (var i = 0; i < this.selids.length; i++) {
          if (this.selids[i].videoCount > 0) {
            this.$message({
              type: 'info',
              message: '活动' + this.selids[i].name + '存在数据信息，不能删除'
            });
            return;
          }
          activityids.push(this.selids[i].id);
        }

        this.$post('/delActivityInfoByIds', { "activityids": activityids.join() }).then(rep => {
          if(rep.resultCode == 0){
            this.$message({
              type: 'success',
              message: '删除成功'
            });
            this.init();
          }else{
            this.$message({
							type: 'error',
							message: '操作失败!'
						});
          }
        })
      }).catch(() => {
      });

    },
  }
};
</script>

<style lang="scss" scoped>
</style>